@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Arial', sans-serif;
  background-color: rgb(6, 19, 4);
}
title {
  text-align: left;
}
